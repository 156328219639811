import useStaticContent from "hooks/use-static-content";
import React from "react";
import styled from "styled-components";
import contentArea from "styles/content-area";
import SEO from "../components/seo";

const Root = styled.div`
    padding-top: 200px;
`;

const Content = styled.div`
    ${contentArea()};
    text-align: center;
`;

const Heading = styled.h1``;
const Text = styled.p``;

const ScoresNotActive = props => {
    const staticContent = useStaticContent().scoresNotActive;
    return (
        <>
            <SEO title="Scores platform not available" />
            <Root>
                <Content>
                    <Heading>{staticContent.header}</Heading>
                    <Text>{staticContent.description}</Text>
                </Content>
            </Root>
        </>
    );
};
export default ScoresNotActive;
