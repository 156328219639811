import { useLocale } from "contexts/locale-context";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

const metadata = {
    gdansk: {
        title: "Dołącz do rowerowej zabawy",
        meta: [
            {
                name: "description",
                content:
                    "W tej grze sportowiec amator ma podobne szanse co weekendowy rowerzysta. Udział w rywalizacji pomoże Ci zbudować lub wzmocnić zdrowe nawyki poprzez dobrą zabawę.",
            },
            {
                name: "keywords",
                content:
                    "gra rowerowa, kręcę dla gdańska, kręć kilometry, gdańska rywalizacja, kręć kilometry dla Gdańska, wkręć się w sopot, rywalziacja rowerowa, rowerem do pracy, bike to work, rowerowy konkurs, bike day, pracownicy rower, pracownicy rower, rowerowa rywalizacja, wellbeing, CSR, grywalizacja, bike2work, employer branding, Pomoc mierzona kilometrami, Well being w pracy, Gra rowerowa, Odpowiedzialny biznes, CSR, Zaangażowani pracownicy, Program zdrowotny, Proste narzędzie well-beingowe, Zdrowe miejsce pracy, Endomondo, Strava",
            },
            {
                property: "og:url",
                content: "http://www.grarowerowa.pl",
            },
            {
                property: "og:type",
                content: "website",
            },
            {
                property: "og:title",
                content:
                    "Zdobywaj punkty na rowerze, wygrywaj nagrody. Dołącz do drużyn firmowych lub zaproś znajomych.",
            },
            {
                property: "og:description",
                content:
                    "W tej grze sportowiec amator ma podobne szanse co weekendowy rowerzysta. Udział w rywalizacji pomoże Ci zbudować lub wzmocnić zdrowe nawyki poprzez dobrą zabawę!",
            },
            {
                property: "og:image",
                content: "https://grarowerowa.pl/images/FB_1200x628px.jpg",
            },
            {
                property: "og:site_name",
                content: "Rowerowa Gra Activy w Twoim mieście",
            },
        ],
    },
    sopot: {
        title: "Dołącz do rowerowej zabawy",
        meta: [
            {
                name: "description",
                content:
                    "W tej grze sportowiec amator ma podobne szanse co weekendowy rowerzysta. Udział w rywalizacji pomoże Ci zbudować lub wzmocnić zdrowe nawyki poprzez dobrą zabawę.",
            },
            {
                name: "keywords",
                content:
                    "gra rowerowa, kręcę dla gdańska, kręć kilometry, gdańska rywalizacja, kręć kilometry dla Gdańska, wkręć się w sopot, rywalziacja rowerowa, rowerem do pracy, bike to work, rowerowy konkurs, bike day, pracownicy rower, pracownicy rower, rowerowa rywalizacja, wellbeing, CSR, grywalizacja, bike2work, employer branding, Pomoc mierzona kilometrami, Well being w pracy, Gra rowerowa, Odpowiedzialny biznes, CSR, Zaangażowani pracownicy, Program zdrowotny, Proste narzędzie well-beingowe, Zdrowe miejsce pracy, Endomondo, Strava",
            },
            {
                property: "og:url",
                content: "http://www.grarowerowa.pl/sopot",
            },
            {
                property: "og:type",
                content: "website",
            },
            {
                property: "og:title",
                content:
                    "Zdobywaj punkty na rowerze, wygrywaj nagrody. Dołącz do drużyn firmowych lub zaproś znajomych.",
            },
            {
                property: "og:description",
                content:
                    "W tej grze sportowiec amator ma podobne szanse co weekendowy rowerzysta. Udział w rywalizacji pomoże Ci zbudować lub wzmocnić zdrowe nawyki poprzez dobrą zabawę!",
            },
            {
                property: "og:image",
                content: "https://activyproduction.blob.core.windows.net/assets/activy_opengraph_1200_630.png",
            },
            {
                property: "og:site_name",
                content: "Rowerowa Gra Activy w Twoim mieście",
            },
        ],
    },
};

interface SEOProps {
    description?: string;
    lang?: string;
    meta?: any[];
    keywords?: string[];
    title: string;
}
function SEO({ description, meta = [], keywords = [], title }: SEOProps) {
    const lang = useLocale();
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metadata[lang].title}
            titleTemplate={`%s | ${metadata[lang].title}`}
            meta={metadata[lang].meta}
        />
    );
}

SEO.defaultProps = {
    lang: "pl",
    meta: [],
    keywords: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`;
